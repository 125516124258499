<template>
    <div class="box">
        <div class="row justify-content-md-center">
            <div class="col-md-12 col-lg-8">
                <b-message
                    title="Votre demande de retrait a bien été prise en compte"
                    type="is-success"
                    has-icon
                    class="pb-0 mb-0"
                    :closable="false"
                    aria-close-label="Close message">
                    Nous avons bien enregistré votre demande de retrait et y procèderons dans les plus brefs délais
                </b-message>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PartnerWithdrawalComplete',
    title: 'partenaire-gains.title',
    components: {},
    data() {
        return {}
    },
    created() {

    },
    computed: {},
    methods: {}
}
</script>

<style scoped>
:deep(p) {
    margin-bottom: 0;
}
</style>